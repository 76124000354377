import styled from '@emotion/styled'

import WysiwygBase from '@/components/Wysiwyg'
import ButtonBase from '@/components/Button'
import { GridContainer } from '@/components/Grid'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(${({ theme }) => `${theme.colors.black}35`}, ${({ theme }) => `${theme.colors.black}35`}), url(${props => props.background});
  background-size: cover;
  background-position: center;
  z-index: 3;
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.black}50`};
`

export const Content = styled(GridContainer)`
  z-index: 1;
`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  display: block;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 34px;
  ${mediaMax.xs} {
    margin-bottom: 2.6rem;
  }
`

export const Title = styled.h2`
  ${sectionTitle()}
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 34px;
  ${mediaMax.xs} {
    margin-bottom: 3rem;
  }
`

export const Text = styled(WysiwygBase)`
  max-width: 28vw;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 39px;

  ${mediaMax.xs} {
    font-size: 1.8rem;
    line-height: 1.6em;
    max-width: 85vw;
    margin-bottom: 4rem;
  }
`

export const Button = styled(ButtonBase)`
  display: inline-flex;
  width: auto;
  padding: 1.8rem 4rem;

  span {
    font-size: 1.2rem;
  }
`
