import * as React from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  Overlay,
  Content,
  Subtitle,
  Title,
  Text,
  Button
} from './style'

const Item = ({
  subtitle = '',
  title = '',
  text = '',
  ctaLabel = '',
  ctaTo = '',
  background
}) => {
  return (
    <Container background={ resolveAssetURL(background) }>
      <Overlay>
        <Content>
          <Subtitle>{ subtitle }</Subtitle>
          <Title>{ title }</Title>
          <Text>{ text }</Text>
          <Button
            to={ ctaTo }
          >
            { ctaLabel }
          </Button>
        </Content>
      </Overlay>
    </Container>
  )
}

Item.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaTo: PropTypes.string,
  background: PropTypes.string.isRequired
}

export default Item
