import * as React from 'react'
import PropTypes from 'prop-types'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, EffectFade } from 'swiper'

import Item from '@/components/HomeHero/Item'
import ItemCountDown from '@/components/HomeHero/ItemCountDown'

import { Wrapper, CircularButton } from './style'

const HomeHero = ({ slides, scrollDownButton = false }) => {
  if (slides.length === 1) {
    return (
      slides[0].revealDate
        ? <ItemCountDown { ...slides[0] } />
        : <Item { ...slides[0] } />
    )
  }

  return (
    <Wrapper>
      <Swiper
        modules={ [Pagination, Autoplay, EffectFade] }
        pagination={ { clickable: true } }
        loop
        autoplay
        effect='fade'
      >
        {
            slides.map((slide, i) => {
              return (
                <SwiperSlide key={ `slide-${i}` }>
                  {
                    slide.revealDate
                      ? <ItemCountDown { ...slide } />
                      : <Item { ...slide } />
                  }
                </SwiperSlide>
              )
            })
          }

        {scrollDownButton && (
          <CircularButton
            id="textcercle"
            icon='arrow-down'
            label='Discover'
            color='white'
            nbLabels={ 5 }
            onClick={ () => {
              window.scroll({
                top: window.innerHeight,
                left: 0,
                behavior: 'smooth'
              })
            } }
          />
        )}
      </Swiper>
    </Wrapper>
  )
}

HomeHero.propTypes = {
  slides: PropTypes.array.isRequired,
  scrollDownButton: PropTypes.bool
}

export default HomeHero
