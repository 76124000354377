import styled from '@emotion/styled'

import CircularLabelButton from '@/components/CircularLabelButton'

import { mediaMax } from '@/styles/mixins'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper {
    width: 100%;

    .swiper-pagination {
      width: auto;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      left: 10%;
      bottom: 7.5vh;
      ${mediaMax.xs} {
        left: 0;
        bottom: 3vh;
        width: 100%;
        gap: 3.4rem;
        padding: 0 3.4rem;
      }

      .swiper-pagination-bullet {
        height: 10px;
        width: 6vw;
        margin-right: 3.5vw;
        margin-left: 0;
        border-radius: 0;
        opacity: 0.5;
        transition: all ease-out 300ms;
        position: relative;
        background: transparent;

        ${mediaMax.xs} {
          width: unset;
          margin-right: unset;
          flex: 1;
        }

        &:after {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: ${({ theme }) => theme.colors.white};
          transition: all ease-out 300ms;
        }

        &:hover {
          &:after {
            height: 5px;
          }
        }

        &.swiper-pagination-bullet-active {
          opacity: 1;
          ${mediaMax.xs} {
            &:after {
              height: 2px;
            }
          }
        }
      }
    }
  }
`

export const CircularButton = styled(CircularLabelButton)`
  position: absolute;
  left: calc(50% + 60rem);
  bottom: ${({ theme }) => theme.spacing.medium};
  transform: translateX(-100%);
  z-index: 2;
`
